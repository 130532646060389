import { useEffect, useLayoutEffect } from 'react';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { ClientTypeUtils } from '../../types';
import { useIsLiveGamePlay } from '../Game/hooks';
import { useIsStreamSessionAliveOrAborted } from '../Session';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import {
  useTownhallAPI,
  useTownhallConfig,
  useTownhallEnabled,
} from './Provider';

function useStreamSessionStatusTrigger(idealCountdownSec: number) {
  const isSessionLiveOrAborted = useIsStreamSessionAliveOrAborted();
  const api = useTownhallAPI();
  useEffect(() => {
    if (!isSessionLiveOrAborted) return;
    api.setNext({
      mode: 'team',
      countdownSec: idealCountdownSec,
      type: 'global',
      source: 'LiveGameStarted',
    });
    return () => {
      api.setNext({
        mode: 'crowd',
        countdownSec: idealCountdownSec,
        type: 'global',
        source: 'LiveGameEnded',
      });
    };
  }, [api, idealCountdownSec, isSessionLiveOrAborted]);
}

function Triggers(props: { idealCountdownSec: number }): JSX.Element | null {
  const { idealCountdownSec } = props;
  useStreamSessionStatusTrigger(idealCountdownSec);
  return null;
}

function ForceTeamMode(): JSX.Element | null {
  const api = useTownhallAPI();
  useLayoutEffect(() => {
    api.setNext({
      mode: 'team',
      countdownSec: 0,
      type: 'global',
      source: 'LiveForceTeamMode',
      policy: 'mandatory',
    });
  }, [api]);

  return null;
}

function ForceCrowdMode(): JSX.Element | null {
  const api = useTownhallAPI();
  useLayoutEffect(() => {
    api.setNext({
      mode: 'crowd',
      countdownSec: 0,
      type: 'global',
      source: 'LiveForceTeamMode',
      policy: 'mandatory',
    });
  }, [api]);

  return null;
}

function useSyncForceMode(
  enabled: boolean,
  flag = getFeatureQueryParamArray('townhall-force-mode')
) {
  const api = useTownhallAPI();
  useEffect(() => {
    if (!enabled) return;
    if (flag === 'auto') {
      api.disableForceMode();
    } else {
      api.setForceMode(flag);
    }
  }, [api, enabled, flag]);
}

export function LiveGameAutoSwitchTownhallMode(): JSX.Element | null {
  const isLiveGame = useIsLiveGamePlay();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const townhallEnabled = useTownhallEnabled();
  const townhallConfig = useTownhallConfig();
  const enabled = isLiveGame && isHost && townhallEnabled;

  useSyncForceMode(enabled);

  if (!enabled) return null;

  switch (townhallConfig.forceMode) {
    case 'crowd':
      return <ForceCrowdMode />;
    case 'team':
      return <ForceTeamMode />;
    case 'disabled':
    default:
      break;
  }

  return <Triggers idealCountdownSec={0} />;
}
