import { useRef, useState } from 'react';

import ChooseTabAudioShare1x from '../../assets/img/audio-share-choose-tab@1x.png';
import ChooseTabAudioShare3x from '../../assets/img/audio-share-choose-tab@3x.png';
import Step2AudioShareCheckbox1x from '../../assets/img/audio-share-step-2@1x.png';
import Step2AudioShareCheckbox3x from '../../assets/img/audio-share-step-2@3x.png';
import AppleMusic1x from '../../assets/img/streaming-services/apple-music@1x.png';
import AppleMusic2x from '../../assets/img/streaming-services/apple-music@2x.png';
import AppleMusic3x from '../../assets/img/streaming-services/apple-music@3x.png';
import Pandora1x from '../../assets/img/streaming-services/pandora@1x.png';
import Pandora2x from '../../assets/img/streaming-services/pandora@2x.png';
import Pandora3x from '../../assets/img/streaming-services/pandora@3x.png';
import Spotify1x from '../../assets/img/streaming-services/spotify@1x.png';
import Spotify2x from '../../assets/img/streaming-services/spotify@2x.png';
import Spotify3x from '../../assets/img/streaming-services/spotify@3x.png';
import Youtube1x from '../../assets/img/streaming-services/youtube@1x.png';
import Youtube2x from '../../assets/img/streaming-services/youtube@2x.png';
import Youtube3x from '../../assets/img/streaming-services/youtube@3x.png';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import logger from '../../logger/logger';
import {
  MusicShareNotSupportedError,
  MusicSharePermissionDeniedError,
} from '../../services/webrtc';
import { Modal } from '../common/Modal';
import { useMusicShareContext } from '../MusicShareContext';

const shareMusicLogger = logger.scoped('share-music');

const ShareMusicCheckboxImage = (props: { className?: string }) => (
  <img
    className={props.className ?? ''}
    srcSet={`${Step2AudioShareCheckbox1x}, ${Step2AudioShareCheckbox3x} 3x`}
    alt={`An arrow pointing at the checked "Share audio" checkbox within Chrome's Select Tab for Sharing dialog box`}
  />
);

const ServiceBtn = (props: { url: string; srcSet?: string; alt: string }) => {
  return (
    <a
      href={props.url}
      className='btn-primary w-25 h-10 flex flex-shrink-0 items-center justify-center'
      target='_blank'
      rel='noreferrer'
    >
      {props.srcSet ? <img srcSet={props.srcSet} alt={props.alt} /> : props.alt}
    </a>
  );
};

const StepHeading = (props: { step: string; info: string }) => {
  return (
    <div className='h-1/6 flex items-center mt-5 px-8 gap-8 w-full'>
      <h3 className=' text-icon-gray text-2xl whitespace-nowrap '>
        {props.step}
      </h3>
      <p className='text-white text-sm font-bold'>{props.info}</p>
    </div>
  );
};

const StepPanel = (props: { children: JSX.Element }) => {
  return (
    <div
      className='
        rounded-xl bg-light-gray
        flex flex-col justify-between items-center
      '
    >
      {props.children}
    </div>
  );
};

const ErrorPanel = (props: { message: string; onClose: () => void }) => {
  return (
    <>
      <button
        className='
            btn-secondary w-34 h-10
            absolute right-3 top-3
          '
        onClick={props.onClose}
      >
        Try Again
      </button>
      <h2 className='text-2xl mt-7.5 mb-4'>Unable to Detect Audio Tab</h2>
      <div className='w-full text-icon-gray text-sms flex flex-col items-center'>
        <p className='px-50 text-center'>
          Please make sure to specifically share a browser tab (not an entire
          screen or application window) and enable the “Share audio” option.
        </p>
      </div>
      <img
        className='mt-11 mb-6'
        srcSet={`${ChooseTabAudioShare1x}, ${ChooseTabAudioShare3x} 3x`}
        alt='An arrow pointing at the Chrome Tab tab in the Screenshare dialog box'
      />
      <ShareMusicCheckboxImage className='mb-12' />
    </>
  );
};

export function MusicShareModal(props: { onClose: () => void }): JSX.Element {
  const ctx = useMusicShareContext();
  const [showErrorModal, setShowErrorModal] = useState<null | Error>(null);
  const [showNotSupportedModal, setShowNotSupportedModal] =
    useState<null | Error>(null);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => props.onClose());

  return (
    <Modal borderStyle={'gray'}>
      {showNotSupportedModal ? (
        <div ref={ref} className='w-100 px-10 pb-8 relative text-center'>
          <h2 className='text-2xl mt-10 mb-6 text-white'>
            Share Music Not Supported by Browser
          </h2>
          <p className='text-gray-500 text-xs'>
            Your browser does not allow sharing audio from a browser tab. Please
            use{' '}
            <a className='text-primary' href='https://google.com/chrome'>
              Google Chrome
            </a>{' '}
            to access this feature on Luna Park.
          </p>
          <button
            className='btn-primary w-34 h-10 mt-6'
            onPointerUp={props.onClose}
          >
            Ok
          </button>
        </div>
      ) : (
        <div
          ref={ref}
          className='w-216 relative flex flex-col items-center text-white'
        >
          {showErrorModal ? (
            <ErrorPanel
              message={showErrorModal.message}
              onClose={() => {
                try {
                  ctx.stop();
                } catch (err) {}
                setShowErrorModal(null);
              }}
            />
          ) : (
            <>
              <button
                className='
            btn-secondary w-34 h-10
            absolute right-3 top-3
          '
                onClick={props.onClose}
              >
                Cancel
              </button>
              <h2 className='text-2xl mt-7.5 mb-4'>
                Stream your music on Luna Park!
              </h2>
              <div className='w-full text-icon-gray text-sms flex flex-col items-center'>
                <p className='px-50'>
                  Share music from a browser tab on the web. Luna Park will only
                  share audio from the source, and the tab itself will not be
                  visible to Players.
                </p>
              </div>
              <div className='w-full flex-grow flex items-stretch justify-between gap-3 px-7 mt-9'>
                <StepPanel>
                  <>
                    <StepHeading
                      step='Step 1'
                      info='Select any streaming service to open in a new tab'
                    />
                    <div className='flex flex-wrap gap-2 w-52 mb-12'>
                      <ServiceBtn
                        url='https://open.spotify.com'
                        srcSet={`${Spotify1x}, ${Spotify2x} 2x, ${Spotify3x} 3x`}
                        alt='Spotify'
                      />
                      <ServiceBtn
                        url='https://music.apple.com'
                        srcSet={`${AppleMusic1x}, ${AppleMusic2x} 2x, ${AppleMusic3x} 3x`}
                        alt='Apple Music'
                      />
                      <ServiceBtn
                        url='https://youtube.com'
                        srcSet={`${Youtube1x}, ${Youtube2x} 2x, ${Youtube3x} 3x`}
                        alt='Youtube'
                      />
                      <ServiceBtn
                        url='https://pandora.com'
                        srcSet={`${Pandora1x}, ${Pandora2x} 2x, ${Pandora3x} 3x`}
                        alt='Pandora Radio'
                      />
                      <ServiceBtn url='about:blank' alt='Other' />
                    </div>
                  </>
                </StepPanel>
                <StepPanel>
                  <>
                    <StepHeading
                      step='Step 2'
                      info='Share audio from the new tab.'
                    />
                    <div className='flex flex-col justify-between items-center px-12 pb-8'>
                      <button
                        className='btn-primary px-4 py-2 my-2'
                        onPointerUp={async () => {
                          try {
                            await ctx.start();
                            props.onClose();
                          } catch (err) {
                            shareMusicLogger.error('start error', err);
                            if (err instanceof MusicShareNotSupportedError) {
                              // browser does not support screenshare with audio
                              setShowNotSupportedModal(err);
                              return;
                            }

                            if (
                              err instanceof MusicSharePermissionDeniedError
                            ) {
                              // user likely canceled the screenshare dialog. Just return to the dialog.
                              return;
                            }

                            setShowErrorModal(err as UnassertedUnknown);
                          }
                        }}
                      >
                        Share Audio
                      </button>
                      <p className=' text-white text-sm pt-2 pb-4 text-center'>
                        Select the new tab and make sure to enable "Share
                        audio".
                      </p>
                      <ShareMusicCheckboxImage />
                    </div>
                  </>
                </StepPanel>
              </div>
              <div className='pt-3 pb-2'>
                <a
                  href='https://www.notion.so/narvii/How-To-Stream-Music-from-a-Music-App-on-a-PC-Windows-10-d5fe4b96fb64418fab1452ac1bdbeea9'
                  className='text-primary text-3xs'
                  target='_blank'
                  rel='noreferrer'
                >
                  How-To: Share desktop audio
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}
