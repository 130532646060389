import {
  TownhallModeToggle,
  useTownhallEnabled,
} from '../../components/Townhall';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';

export function LiveGameTownhallModeToggle(props: {
  zIndex: `z-${number}`;
}): JSX.Element | null {
  const toggleEnabled = useFeatureQueryParam('townhall-live-host-toggle');
  const townhallEnabled = useTownhallEnabled();
  if (!townhallEnabled || !toggleEnabled) return null;
  return (
    <div className={`absolute right-5 top-14 ${props.zIndex}`}>
      <TownhallModeToggle />
    </div>
  );
}
