import pluralize from 'pluralize';
import { useEffect, useMemo } from 'react';

import { useInstance } from '../../hooks/useInstance';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import { useQueryParam } from '../../hooks/useQueryParam';
import logger from '../../logger/logger';
import { type OnlineGameBundle } from '../../types';
import { joinNames } from '../../utils/string';
import { ValtioUtils } from '../../utils/valtio';
import { useDatabaseRef } from '../Firebase';
import { useFetchGameSessionGamePack } from '../Game/hooks';
import { useMyOrgId } from '../Organization';
import { useIsPairingGamePublished, usePairing } from '../Pairing';
import { useParticipantsAsArray } from '../Player';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useVenue } from '../Venue/VenueProvider';
import { OnlineGameFirebaseUtils } from './utils';

const log = logger.scoped('online-game-tracking');

function useParticipantsDescription(): string {
  const myClientId = useMyClientId();
  const participants = useParticipantsAsArray({
    filters: ['status:connected', 'host:false'],
    sorts: ['joinedAt:asc'],
  });

  return useMemo(() => {
    const sorted = participants.sort((a, _) =>
      a.clientId === myClientId ? -1 : 0
    );

    if (sorted.length === 0) {
      return '0 people in the venue';
    }
    if (sorted.length <= 3) {
      return joinNames(sorted.map((p) => p.username));
    }
    return `${sorted
      .slice(0, 3)
      .map((p) => p.username)
      .join(', ')} and ${sorted.length - 3} ${pluralize(
      'other',
      sorted.length - 3
    )}`;
  }, [myClientId, participants]);
}

function useQueryOrgId(): string | null {
  return useQueryParam('org-id');
}

function useGameOrgId(): string | null {
  const orgIdInQuery = useQueryOrgId();
  const myOrgId = useMyOrgId();
  return orgIdInQuery || myOrgId;
}

export function OnlineGameTracking(): JSX.Element | null {
  const myOrgId = useMyOrgId();
  const gameOrgId = useGameOrgId();
  const [venue] = useVenue();
  const inOrgRef = useDatabaseRef<OnlineGameBundle>(
    OnlineGameFirebaseUtils.InOrgPath(myOrgId || '', venue.id)
  );
  const crossOrgRef = useDatabaseRef<OnlineGameBundle>(
    OnlineGameFirebaseUtils.CrossOrgPath(venue.id)
  );
  const isPairingPublished = useIsPairingGamePublished();
  const gamePack = useFetchGameSessionGamePack();
  const pairing = usePairing();
  const orgIdInQueryParam = useQueryOrgId();

  const participantsDesc = useParticipantsDescription();
  const onlineAt = useInstance(() => Date.now());
  const isCoordinator = useIsCoordinator();

  useEffect(() => {
    if (!isCoordinator) return;
    if (!isPairingPublished) return;
    if (!!pairing) return;

    const bundle: OnlineGameBundle = {
      venue: ValtioUtils.detachCopy(venue),
      gamePack,
      participantsDesc: participantsDesc,
      pairing: pairing,
      gameOrgId,
      onlineAt: onlineAt,
      updatedAt: Date.now(),
    };

    const run = async () => {
      if (myOrgId) {
        log.info('InOrg Game online, set firebase', { bundle });
        await inOrgRef.onDisconnect().remove();
        await inOrgRef.set(bundle);
      }
      if (gameOrgId && gameOrgId !== myOrgId) {
        log.info('Cross Org Game online, set firebase', { bundle });
        await crossOrgRef.onDisconnect().remove();
        await crossOrgRef.set(bundle);
      }
    };

    run();
  }, [
    inOrgRef,
    venue,
    gamePack,
    participantsDesc,
    pairing,
    orgIdInQueryParam,
    crossOrgRef,
    gameOrgId,
    myOrgId,
    onlineAt,
    isPairingPublished,
    isCoordinator,
  ]);

  useEffect(() => {
    return () => {
      log.info('Game offline, remove firebase', { myOrgId, gameOrgId });
      inOrgRef.remove();
      crossOrgRef.remove();
    };
  }, [crossOrgRef, gameOrgId, inOrgRef, myOrgId]);

  return null;
}
