import { SwitcherControlled } from '../Switcher';
import { useTownhallAPI, useTownhallConfig, useTownhallNext } from './Provider';

function Label(props: { text: string; active: boolean }): JSX.Element {
  return (
    <div
      className={`text-3xs ${
        props.active ? 'text-white font-bold' : 'text-[#CCC]'
      }`}
    >
      {props.text}
    </div>
  );
}

export function TownhallModeToggle(): JSX.Element | null {
  const api = useTownhallAPI();
  const config = useTownhallConfig();
  const next = useTownhallNext();
  const current = next?.mode || config.mode;

  const handleChange = async (v: boolean) => {
    api.setNext({
      mode: v ? 'crowd' : 'team',
      countdownSec: 3,
      type: 'global',
      source: 'host-venue-toggle',
      policy: 'mandatory',
    });
  };

  return (
    <div className='flex items-center justify-center bg-black bg-opacity-50 px-3 py-1 rounded-2xl text-white'>
      <Label text='Teams' active={current === 'team'} />
      <div className='mx-2'>
        <SwitcherControlled
          name='townhall-toggle-mode'
          checked={current === 'crowd'}
          disabled={!!next || !config.enabled}
          onChange={handleChange}
        />
      </div>
      <Label text='Townhall' active={current === 'crowd'} />
    </div>
  );
}
