export class OnlineGameFirebaseUtils {
  static InOrgPath(orgId: string, venueId: string): string {
    return `${this.InOrgCollectionPath(orgId)}/${venueId}`;
  }
  static CrossOrgPath(venueId: string): string {
    return `${this.CrossOrgCollectionPath()}/${venueId}`;
  }
  static InOrgCollectionPath(orgId: string): string {
    return `online-games/${orgId}`;
  }
  static CrossOrgCollectionPath(): string {
    return `online-games/cross-org`;
  }
}
