import { useCloneSingletonMediaStream } from '../../components/Device';
import { GamePlayHostVideo } from '../../components/Game/Blocks/Common/GamePlay';
import { useIsStreamSessionAlive } from '../../components/Session';
import {
  StreamingActiveMode,
  useStreamingMode,
} from '../../components/StreamingTools/hooks';
import { useLocalVideoEffectsSettings } from '../../components/VideoEffectsSettings/Storage';
import { VideoEffectsMediaElement } from '../../components/VideoFrameProcessing';
import { BaseLocalStreamView, useRTCService } from '../../components/WebRTC';

export function HostLocalStreamView(): JSX.Element | null {
  const isSessionAlive = useIsStreamSessionAlive();
  const streamingMode = useStreamingMode();
  const rtcService = useRTCService('stage');

  if (!isSessionAlive) return null;

  // Note(drew): There is duplication between Rec/Live, but matching 1:1
  // with a mode is better than reducing duplication at the expense of
  // complexity.

  switch (streamingMode) {
    case StreamingActiveMode.OnD: {
      return <GamePlayHostVideo />;
    }
    case StreamingActiveMode.Live: {
      return (
        <BaseLocalStreamView
          id='host-stream'
          className='w-screen h-full z-0 absolute'
          publishVideoTrackIfChanged={true}
          publishAudioTrackIfChanged={true}
          enablePlaceholder={false}
          enableOnStage={false}
          rtcService={rtcService}
        />
      );
    }
    case StreamingActiveMode.Rec: {
      return (
        <BaseLocalStreamView
          id='host-stream'
          className='w-screen h-full z-0 absolute'
          publishVideoTrackIfChanged={true}
          publishAudioTrackIfChanged={true}
          enablePlaceholder={false}
          enableOnStage={false}
          rtcServicePlaysVideo={false}
          rtcService={rtcService}
        >
          <GreenScreenHostStreamPreview className='w-full h-full' />
        </BaseLocalStreamView>
      );
    }
  }
}

function GreenScreenHostStreamPreview(props: {
  className?: string;
}): JSX.Element | null {
  const settings = useLocalVideoEffectsSettings();
  const stream = useCloneSingletonMediaStream();

  if (!stream) return null;

  return (
    <VideoEffectsMediaElement
      className={props.className}
      fit={'object-cover'}
      streamOrSrc={stream}
      muted
      autoplay
      settings={settings}
    />
  );
}
